import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      defaultProps: {
        color: theme.palette.typoColor.main,
        variantMapping: {
          h5_questions: 'h5',
          h5_semibold: 'h5',
          h5_xl: 'h5',
          h5_l: 'h5',
          h5_m: 'h5',
          h5_s: 'h5',
          h5_xs: 'h5',
          body_l_medium: 'p',
          body_l_regular: 'p',
          body_m_medium: 'p',
          body_m_regular: 'p',
          body_s_medium: 'p',
          body_s_regular: 'p',
          detail_regular: 'span',
        },
      },
    },
  }
}
