import { Theme } from '@mui/material'

const PaginationItem = (theme: Theme) => {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: theme.palette.mediumGreen.main,
            },
            color: theme.palette.white.main,
            backgroundColor: theme.palette.mediumGreen.main,
            '&.Mui-disabled': {
              backgroundColor: theme.palette.mediumGreen.disabled,
            },
          },
        },
      },
    },
  }
}

export default PaginationItem
