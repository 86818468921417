import { Theme } from '@mui/material/styles'
import Button from './Button'
import Typography from './Typography'
import CircularProgress from './CircularProgress'
import Checkbox from './CheckBox'
import PaginationItem from './Pagination'

export default function componentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Typography(theme),
    CircularProgress(theme),
    Checkbox(theme),
    PaginationItem(theme)
  )
}
