interface CustomShadowOptions {
  primary: string
  whiteButton: string
}

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: CustomShadowOptions
  }
  interface ThemeOptions {
    customShadows?: CustomShadowOptions
  }
}

const customShadows = {
  primary: '0px 6px 15px 0px rgba(64, 144, 26, 0.05);',
  whiteButton: '0px 0px 5px 0px rgba(41, 91, 84, 0.25);',
}

export default customShadows
