import { createTheme } from '@mui/material'

import componentsOverrides from './overrides'
import palette from './palette'
import typography from './typography'
import customShadows from './customShadows'

const muiTheme = createTheme({
  shape: { borderRadius: 8 },
  typography,
  palette,
  customShadows,
})

// @ts-ignore
muiTheme.components = componentsOverrides(muiTheme)

export default muiTheme
