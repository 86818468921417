import { TypographyOptions } from '@mui/material/styles/createTypography'
import { Poppins, Noto_Sans } from 'next/font/google'

export const poppins = Poppins({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  variable: '--font-poppins',
})

export const noto_sans = Noto_Sans({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  variable: '--font-noto_sans',
})

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h5_questions: React.CSSProperties
    h5_semibold: React.CSSProperties
    h5_xl: React.CSSProperties
    h5_l: React.CSSProperties
    h5_m: React.CSSProperties
    h5_s: React.CSSProperties
    h5_xs: React.CSSProperties
    body_l_medium: React.CSSProperties
    body_l_regular: React.CSSProperties
    body_m_medium: React.CSSProperties
    body_m_regular: React.CSSProperties
    body_s_medium: React.CSSProperties
    body_s_regular: React.CSSProperties
    detail_regular: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h5_questions?: React.CSSProperties
    h5_semibold?: React.CSSProperties
    h5_xl?: React.CSSProperties
    h5_l?: React.CSSProperties
    h5_m?: React.CSSProperties
    h5_s?: React.CSSProperties
    h5_xs?: React.CSSProperties
    body_l_medium?: React.CSSProperties
    body_l_regular?: React.CSSProperties
    body_m_medium?: React.CSSProperties
    body_m_regular?: React.CSSProperties
    body_s_medium?: React.CSSProperties
    body_s_regular?: React.CSSProperties
    detail_regular?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h5_questions: true
    h5_semibold: true
    h5_xl: true
    h5_l: true
    h5_m: true
    h5_s: true
    h5_xs: true
    body_l_medium: true
    body_l_regular: true
    body_m_medium: true
    body_m_regular: true
    body_s_medium: true
    body_s_regular: true
    detail_regular: true
  }
}

export const remToPx = (value: string) => {
  return Math.round(parseFloat(value) * 16)
}

export const pxToRem = (value: number) => {
  return `${value / 16}rem`
}

type Breakpoint = {
  sm: number
  md: number
  lg: number
  xl: number
}

export const responsiveFontSizes = ({ sm, md, lg, xl }: Breakpoint) => {
  return {
    '@media (max-width: 600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width: 601px) and (max-width: 900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width: 901px) and (max-width: 1200px)': {
      fontSize: pxToRem(lg),
    },
    '@media (min-width: 1201px)': {
      fontSize: pxToRem(xl),
    },
  }
}

const typography: TypographyOptions = {
  fontFamily: noto_sans.style.fontFamily,
  h1: {
    fontFamily: poppins.style.fontFamily,
    fontSize: '4rem',
    fontWeight: 500,
  },
  h2: {
    fontFamily: poppins.style.fontFamily,
    fontSize: '3.5rem',
    fontWeight: 500,
  },
  h3: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 500,
    fontSize: 48,
    // fontSize: 'clamp(1rem, 2.5vw, 3rem)',
    // ...responsiveFontSizes({ sm: 24, md: 32, lg: 40, xl: 48 }),
  },
  h4: {
    fontFamily: poppins.style.fontFamily,
    lineHeight: '3rem',
    fontWeight: 500,
    fontSize: 40,
    // ...responsiveFontSizes({ sm: 20, md: 24, lg: 32, xl: 40 }),
  },
  h5: {
    fontFamily: poppins.style.fontFamily,
  },
  h5_questions: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 500,
    fontSize: 32,
    // ...responsiveFontSizes({ sm: 16, md: 20, lg: 24, xl: 32 }),
  },
  h5_semibold: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 600,
    fontSize: 32,
    // ...responsiveFontSizes({ sm: 16, md: 20, lg: 24, xl: 32 }),
  },
  h5_xl: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 500,
    fontSize: 24,
    // ...responsiveFontSizes({ sm: 12, md: 16, lg: 20, xl: 24 }),
  },
  h5_l: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 500,
    fontSize: 18,
    // ...responsiveFontSizes({ sm: 9, md: 12, lg: 15, xl: 18 }),
  },
  h5_m: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 500,
    fontSize: 16,
    // ...responsiveFontSizes({ sm: 8, md: 12, lg: 14, xl: 16 }),
  },
  h5_s: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 500,
    fontSize: 14,
    // ...responsiveFontSizes({ sm: 8, md: 10, lg: 12, xl: 14 }),
  },
  h5_xs: {
    fontFamily: poppins.style.fontFamily,
    fontWeight: 500,
    fontSize: 12,
    // ...responsiveFontSizes({ sm: 6, md: 8, lg: 10, xl: 12 }),
  },
  h6: {
    fontFamily: poppins.style.fontFamily,
  },
  body_l_medium: {
    fontWeight: 500,
    fontSize: 18,
    // ...responsiveFontSizes({ sm: 9, md: 12, lg: 15, xl: 18 }),
  },
  body_l_regular: {
    fontWeight: 400,
    fontSize: 18,
    // ...responsiveFontSizes({ sm: 10, md: 12, lg: 15, xl: 18 }),
  },
  body_m_medium: {
    fontWeight: 500,
    fontSize: 16,
    // ...responsiveFontSizes({ sm: 10, md: 12, lg: 14, xl: 16 }),
  },
  body_m_regular: {
    fontWeight: 400,
    fontSize: 16,
    // ...responsiveFontSizes({ sm: 8, md: 12, lg: 14, xl: 16 }),
  },
  body_s_medium: {
    fontWeight: 500,
    fontSize: 14,
    // ...responsiveFontSizes({ sm: 8, md: 10, lg: 12, xl: 14 }),
  },
  body_s_regular: {
    fontWeight: 400,
    fontSize: 14,
    // ...responsiveFontSizes({ sm: 8, md: 10, lg: 12, xl: 14 }),
  },
  detail_regular: {
    fontWeight: 400,
    fontSize: 13,
    // ...responsiveFontSizes({ sm: 7, md: 9, lg: 11, xl: 13 }),
  },
}

export default typography
