import { Theme } from '@mui/material'
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    soft?: true
    rounded?: true
  }
}

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      variants: [
        {
          props: { variant: 'soft' } as const,
          style: {
            backgroundColor: '#295B54',
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#0146a6',
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'rounded' } as const,
          style: {
            fontSize: '16px',
            fontWeight: 500,
            border: '1px solid',
            backgroundColor: theme.palette.white.main,
            borderColor: theme.palette.mediumGreen.main,
            color: theme.palette.typoColor.main,
            borderRadius: 30,
            lineHeight: '1.25rem',
            '&:hover': {
              backgroundColor: theme.palette.white.hover,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.buttonColor.main,
          borderRadius: 8,
          color: theme.palette.typoColor.main,
          fontSize: 16,

          fontWeight: 500,
          lineHeight: '1.25rem',
          textTransform: 'none',
          padding: '12px 24px',
          border: '1px solid',
          borderColor: theme.palette.typoColor.main,
          '&:hover': {
            backgroundColor: theme.palette.buttonColor.hover,
          },
          '&:focus': {
            borderColor: theme.palette.mediumGreen.main,
          },
        },
        sizeSmall: {
          padding: '8px 16px',
        },
        outlined: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: theme.palette.lightGreen.main,
            borderColor: theme.palette.typoColor.main,
          },
        },
        text: {
          backgroundColor: 'transparent',
          border: 'none',
          color: theme.palette.mediumGreen.main,
          '&:hover': {
            backgroundColor: theme.palette.lightGreen.main,
            borderColor: theme.palette.typoColor.main,
          },
        },
      },
    },
  }
}

export default Button
