import { PaletteOptions } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    darkGreen: {
      main: string
    }
    mediumGreen: {
      main: string
      disabled: string
    }
    lightGreen: {
      main: string
      hover: string
    }
    darkGreenFifty: {
      main: string
    }
    typoColor: {
      main: string
    }
    buttonColor: {
      main: string
      hover: string
    }
    backgroundInput: {
      main: string
    }
    strokeColor: {
      main: string
    }
    darkGray: {
      main: string
      disabled: string
    }
    mediumGray: {
      main: string
    }
    lightGray: {
      main: string
    }
    white: {
      main: string
      hover: string
    }
  }
  interface PaletteOptions {
    darkGreen?: {
      main: string
    }
    mediumGreen?: {
      main: string
      disabled: string
    }
    lightGreen?: {
      main: string
      hover: string
    }
    darkGreenFifty?: {
      main: string
    }
    typoColor?: {
      main: string
    }
    buttonColor?: {
      main: string
      hover: string
    }
    backgroundInput?: {
      main: string
    }
    strokeColor?: {
      main: string
    }
    darkGray?: {
      main: string
      disabled: string
    }
    mediumGray?: {
      main: string
    }
    lightGray?: {
      main: string
    }
    white?: {
      main: string
      hover: string
    }
  }
}

const palette: PaletteOptions = {
  primary: {
    main: '#1B7754',
  },
  darkGreen: {
    main: '#003D31',
  },
  mediumGreen: {
    main: '#1B7754',
    disabled: '#87B5A4',
  },
  lightGreen: {
    main: '#E5EDE3',
    hover: '#DCE8D9',
  },
  darkGreenFifty: {
    main: '#003D31',
  },
  typoColor: {
    main: '#313131',
  },
  buttonColor: {
    main: '#CBF046',
    hover: '#B6D83F',
  },
  backgroundInput: {
    main: '#F7F7F7',
  },
  strokeColor: {
    main: '#F1F4F0',
  },
  darkGray: {
    main: '#696969',
    disabled: '#AEAEAE',
  },
  mediumGray: {
    main: '#9d9d9d',
  },
  lightGray: {
    main: '#eeeeee',
  },
  white: {
    main: '#FFFFFF',
    hover: '#f3f0f0',
  },
}

export default palette
