import { Theme } from '@mui/material'

const CircularProgress = (theme: Theme) => {
  return {
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.buttonColor.main,
        },
      },
    },
  }
}

export default CircularProgress
