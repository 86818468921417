import type { PropsWithChildren } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import GlobalStyles from '../styles/muiTheme/GlobalStyles'
import muiTheme from '../styles/muiTheme/muiTheme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider } from 'react-redux'
import { store } from '../store/store'
import Script from 'next/script'
import { Toaster } from 'react-hot-toast'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ProgressBar from './Common/ProgressBar/ProgressBar'

type Props = {
  queryClient: QueryClient
}

const Root: React.FC<PropsWithChildren<Props>> = ({
  children,
  queryClient,
}) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <GlobalStyles />
            <ProgressBar />
            <Toaster />
            <Provider store={store}>{children}</Provider>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-/bQdsTh/da6pkI1MST/rWKFNjaCP5gBSY4sEBT38Q/9RBh9AH40zEOg7Hlq2THRZ"
        crossOrigin="anonymous"
      />
    </>
  )
}

export default Root
