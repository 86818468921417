import { Theme } from '@mui/material/styles'
import { CheckboxProps } from '@mui/material'

const CheckboxIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M8.66153 20.9504H14.3333C19.0598 20.9504 20.9504 19.0598 20.9504 14.3333V8.66153C20.9504 3.93503 19.0598 2.04443 14.3333 2.04443H8.66153C3.93503 2.04443 2.04443 3.93503 2.04443 8.66153V14.3333C2.04443 19.0598 3.93503 20.9504 8.66153 20.9504Z"
        stroke="#313131"
        strokeWidth="1.41795"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const CheckboxCheckedIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M8.78213 20.9504H14.4539C19.1804 20.9504 21.071 19.0598 21.071 14.3333V8.66153C21.071 3.93503 19.1804 2.04443 14.4539 2.04443H8.78213C4.05564 2.04443 2.16504 3.93503 2.16504 8.66153V14.3333C2.16504 19.0598 4.05564 20.9504 8.78213 20.9504Z"
        stroke="#313131"
        strokeWidth="1.41795"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.60059 11.4975L10.2758 14.1727L15.6356 8.82227"
        stroke="#1B7754"
        strokeWidth="1.41795"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const Checkbox = (theme: Theme) => {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        // indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: CheckboxProps }) => ({
          padding: theme.spacing(1),
          ...(ownerState.size === 'small' && {
            '& svg': { width: 20, height: 20 },
          }),
          ...(ownerState.size === 'medium' && {
            '& svg': { width: 24, height: 24 },
          }),
        }),
      },
    },
  }
}

export default Checkbox
