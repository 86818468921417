import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cvResponse: null,
  fileInfo: null,
}

const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    setCvResponse(state, action) {
      state.cvResponse = action.payload
    },
    setFileInfo(state, action) {
      state.fileInfo = action.payload
    },
  },
})

export const { setCvResponse, setFileInfo } = boardSlice.actions

export default boardSlice.reducer
