import { configureStore } from '@reduxjs/toolkit'
import jobSlice from './slices/job/jobSlice'
import toggleSlice from './slices/toggle/toggleSlice'
import filterSlice from './slices/filter/filterSlice'
import employerFilterSlice from './slices/filter/employerFilterSlice'
import employerSlice from './slices/employer/employerSlice'
import candidateFilterSlice from './slices/filter/candidateFilterSlice'
import candidateSlice from './slices/candidate/candidateSlice'
import boardSlice from './slices/board/boardSlice'

export const store = configureStore({
  reducer: {
    job: jobSlice,
    toggle: toggleSlice,
    filter: filterSlice,
    employer: employerSlice,
    employerFilter: employerFilterSlice,
    candidateFilter: candidateFilterSlice,
    candidate: candidateSlice,
    board: boardSlice,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(),
})
